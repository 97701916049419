<script setup lang="ts">
import type { ApiLogisticsTaskGetById } from '~/types'

interface Payload {
  task: ApiLogisticsTaskGetById
}
defineProps<Payload>()

const emit = defineEmits<{ (e: 'save', payload: Payload): void }>()

const isCustomerNotReachable = ref(false)

function submit(task: ApiLogisticsTaskGetById) {
  if (isCustomerNotReachable.value) {
    task.deliveryPerson = null
    task.deliveryCustomerSignature = null
  }
  emit('save', { task })
}
</script>

<template>
  <FormKit
    type="form"
    :value="task"
    submit-label="Speichern"
    @submit="submit"
  >
    <FormKit
      name="deliveryPerson"
      type="text"
      label="Eingewiesene Person"
      :validation="isCustomerNotReachable ? '' : 'required'"
      :disabled="isCustomerNotReachable"
    />

    <FormKitSignaturePopup
      name="deliveryCustomerSignature"
      label="Kunden Signatur"
      :disable-by-pass="true"
      :disable-positions-overview="true"
      :default="undefined"
      :validation="isCustomerNotReachable ? '' : 'required'"
      :disabled="isCustomerNotReachable"
    />

    <n-checkbox v-model:checked="isCustomerNotReachable" class="mb-3">
      Kunde nicht erreichbar
    </n-checkbox>
  </FormKit>
</template>
