<script setup lang="ts">
import { joinURL, withQuery } from 'ufo'
import type { ApiLogisticsTaskGetById, MachineryAccessoryCategory, ManuallyCreatableLogisticsType } from '~/types'
import { machineryAccessoryCategoryToGerman } from '~/translations'
import { updateLogisticsTaskStatusSchema } from '~/server/schemas'

const props = defineProps<{ isLoading: boolean, logisticsTask: ApiLogisticsTaskGetById, isForMobile?: boolean }>()

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()

const notification = useNotification()

const { useremail } = useAuthorization()

interface PayloadDeliveryPopup {
  task: ApiLogisticsTaskGetById
}
const update = useMutation({
  mutationFn: async ({ task }: PayloadDeliveryPopup) => {
    // Fill in data for showing in status tileline
    task.deliveredAt = task.deliveredAt ?? new Date()
    task.deliveredByEmail = task.deliveredByEmail ?? useremail.value

    await $trpc.offerPosition.logisticsTour.updateDeliveryChecklist.mutate(task)

    if (task.type === 'a-to-b') {
      return
    }

    const positionStatuses = task.positionsToShip.map((p) => {
      if (p.isDelivered) {
        return {
          id: p.id,
          deliveredAt: p.deliveredAt ?? new Date(),
          deliveredByEmail: p.deliveredByEmail ?? useremail.value,
          isDelivered: true as const,
        }
      }

      return {
        id: p.id,
        deliveredAt: null,
        deliveredByEmail: null,
        isDelivered: false as const,
      }
    })

    const isAllPositionsDelivered = task.positionsToShip.every(p => p.isDelivered)
    let statusToUpdate
    if (isAllPositionsDelivered) {
      statusToUpdate = 'delivered'
    } else {
      statusToUpdate = task.status
    }

    await $trpc.offerPosition.logisticsTour.updateDeliveredAt.mutate({
      taskId: task.id,
      positionStatuses,
    })

    const updateStatusPayload = updateLogisticsTaskStatusSchema.parse({
      id: task.id,
      status: statusToUpdate,
      type: task.type,
    })

    return $trpc.logisticsTask.updateStatus.mutate(updateStatusPayload)
  },
  onError: makeTrpcErrorToast(notification, { description: 'Der Liefer-Status konnte nicht aktualisiert werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['logisticsTask'] }),
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
      queryClient.invalidateQueries({ queryKey: ['machines'] }),
    ])
    closeDeliveryChecklistPopup()
    notification.success({ title: 'Der Liefer-Status wurde auf "Übergeben" gesetzt', duration: 4500 })
  },
})

const machineryPositionOfOffer = computed(() => props.logisticsTask.positionsToShip.find(position => position.type === 'machinery'))
const machineryAccessoryPositionsOfOffer = computed(() => props.logisticsTask.positionsToShip.filter(position => position.type === 'machineryAccessory'))
const specialPositionsOfOffer = computed(() => props.logisticsTask.positionsToShip.filter(position => position.type === 'special'))

const offerHasMachineryOrAccessories = computed(() => Boolean(machineryPositionOfOffer.value?.machinery || machineryAccessoryPositionsOfOffer.value.some(position => position.machineryAccessory)))

const machineryOption = computed(() => {
  const machinery = machineryPositionOfOffer.value?.machinery
  if (!machinery) {
    return {
      label: 'N/A',
      value: false,
    }
  }

  return {
    label: `${machinery.id}, ${machinery.producerCompanyName}, ${machinery.type.name}`,
    value: machineryPositionOfOffer.value.isDelivered,
  }
})

const machineryAccessoryOptions = computed(() => machineryAccessoryPositionsOfOffer.value.map((position) => {
  const accessoryCategory = position.machineryAccessory?.category
    ? machineryAccessoryCategoryToGerman[position.machineryAccessory?.category as MachineryAccessoryCategory]
    : ''

  return {
    label: `${position.machineryAccessoryId}, ${accessoryCategory}`,
    value: position.isDelivered,
  }
}))

const isMachineryHandedOver = computed(() => Boolean(machineryPositionOfOffer.value?.isDelivered))
const isMachineryAccessoryHandedOver = computed(() => machineryAccessoryPositionsOfOffer.value.some(position => position.isDelivered))
const isDeliveryChecklistEmpty = computed(() => !isMachineryHandedOver.value && !isMachineryAccessoryHandedOver.value && !props.logisticsTask.deliveryCustomerSignature)

const { payload: deliveryChecklistPayload, open: openDeliveryChecklistPopup, close: closeDeliveryChecklistPopup } = usePopup<PayloadDeliveryPopup>()

// TODO: We could instead render tours as list
const outboundTask = computed(() => props.logisticsTask.type === 'outbound' ? props.logisticsTask : undefined)
const inboundTask = computed(() => props.logisticsTask.type === 'inbound' ? props.logisticsTask : undefined)
const aToBTask = computed(() => props.logisticsTask.type === 'a-to-b' ? props.logisticsTask : undefined)

function openDeliveryChecklist() {
  const task = outboundTask.value ?? aToBTask.value
  if (!task) {
    return
  }
  openDeliveryChecklistPopup({ task })
}
function saveDeliveryChecklist(payload: PayloadDeliveryPopup) {
  const { task } = payload
  return update.mutate({ task })
}

const { logisticsTaskGeneralInformation, logisticsTaskGeneralInformationForDriver, logisticsTaskPositionsOverview, deliveryChecklist, logisticsCompletedInformation } = useOneDataColumnConfigs()
const { openDriverLoadingChecklistPopup, openDriverCollectionChecklistPopup, openEditTaskAToBStatusPopup, openLogisticsTaskCreateOrEditPopup } = useGlobalOpeners()

const isPlanned = computed(() => props.logisticsTask.deliveryAt && (props.logisticsTask.assignedDeliveryVehicleId || props.logisticsTask.doesCustomerDoTask || props.logisticsTask.doesFitterDoTask || props.logisticsTask.doesTransportationCompanyDoTask))
const isIssued = computed(() => props.logisticsTask.positionsToShip.some(p => p.issuedAt))
const isLoaded = computed(() => props.logisticsTask.positionsToShip.some(p => p.loadedAt))
const isDelivered = computed(() => props.logisticsTask.positionsToShip.every(p => p.deliveredAt))
const isAToBDelivered = computed(() => props.logisticsTask.deliveredAt && props.logisticsTask.type === 'a-to-b')
const isSpecialOffer = computed(() => props.logisticsTask.offer?.type === 'special')

function navigateToDeliveryPDF() {
  if (isSpecialOffer.value) {
    const url = joinURL('/api/pdf/offer/details/', props.logisticsTask.offer?.cuid ?? '')
    const customerLocale = props.logisticsTask.offer?.customer?.language || 'de'
    const query = {
      type: 'deliveryNote',
      locale: customerLocale,
      deliveryPerson: props.logisticsTask.deliveryPerson,
    }
    window.open(withQuery(url, query), '_blank')
  } else {
    const url = joinURL('/api/pdf/logisticsTask/delivery/', props.logisticsTask.id)
    window.open(url, '_blank')
  }
}

const { canAccessPage } = useAuthorization()
</script>

<template>
  <n-skeleton v-if="isLoading" text :repeat="4.75" />
  <div v-else class="w-full space-y-2">
    <ThePopup v-if="deliveryChecklistPayload && !isSpecialOffer" :show="Boolean(deliveryChecklistPayload)" title="Übergabe-Checklist" @close="closeDeliveryChecklistPopup">
      <DriverDeliveryChecklist
        v-bind="deliveryChecklistPayload"
        @save="saveDeliveryChecklist"
      />
    </ThePopup>
    <ThePopup v-if="deliveryChecklistPayload && isSpecialOffer" :show="Boolean(deliveryChecklistPayload)" title="Übergabe-Checklist" @close="closeDeliveryChecklistPopup">
      <DriverSpecialOfferDeliveryChecklist
        v-bind="deliveryChecklistPayload"
        @save="saveDeliveryChecklist"
      />
    </ThePopup>
    <div class="overflow-auto w-full">
      <TheDataCard>
        <div class="flex gap-10">
          <div class="overflow-x-auto">
            <LogisticsStatusTimeline :logistics-task-id="logisticsTask.id" :horizontal="!isForMobile" />
          </div>
          <div v-if="isForMobile" class="flex flex-col justify-end">
            <div v-if="outboundTask" class="flex flex-col gap-2 justify-end">
              <n-button
                type="primary"
                :disabled="!isPlanned || !isIssued || isDelivered"
                @click="openDriverLoadingChecklistPopup.open({ id: logisticsTask.id })"
              >
                Aufladen
              </n-button>
              <n-button
                type="primary"
                :disabled="!isLoaded"
                @click="openDeliveryChecklist"
              >
                Übergeben
              </n-button>
            </div>
            <n-button
              v-else-if="inboundTask"
              type="primary"
              @click="openDriverCollectionChecklistPopup.open({ id: logisticsTask.id })"
            >
              Abholen
            </n-button>
            <div v-else-if="aToBTask" class="flex flex-col gap-2 justify-end">
              <n-button
                type="primary"
                :disabled="!isPlanned"
                @click="openDeliveryChecklist"
              >
                Übergeben
              </n-button>
              <n-button
                :disabled="!isPlanned || !isAToBDelivered"
                type="primary"
                @click="openEditTaskAToBStatusPopup.open({ id: logisticsTask.id })"
              >
                Abgeschlossen
              </n-button>
            </div>
          </div>
        </div>
      </TheDataCard>
    </div>
    <div class="w-full flex gap-x-2 " :class="{ 'flex-col gap-y-2': isForMobile }">
      <div class="flex w-1/2 flex-col gap-y-2" :class="{ 'w-full': isForMobile }">
        <TheDataCard v-if="logisticsTask.offerId" title="Kommentare">
          <div>
            <CommentList :id="logisticsTask.id" type="logisticsTask" list-title-text="Zu dieser Aufgabe" />
          </div>
          <div class="mt-5">
            <CommentList :id="logisticsTask.offerId" type="termination" :is-read-only="true" list-title-text="Aus Auftrag" />
          </div>
          <div class="mt-5">
            <CommentList :id="logisticsTask.id" type="deliveryDate" :is-read-only="true" list-title-text="Zum Anliefertag" />
          </div>
          <div class="mt-5">
            <CommentList :id="logisticsTask.id" type="deliveryTime" :is-read-only="true" list-title-text="Zur Anlieferzeit" />
          </div>
        </TheDataCard>
        <TheDataCard>
          <template #header>
            <div class="CardHeader">
              <h2>Allgemeine Infos</h2>
              <n-button
                v-if="isSpecialOffer && logisticsTask.offer && canAccessPage('/offer')"
                :disabled="logisticsTask.status === 'completed'"
                @click="openLogisticsTaskCreateOrEditPopup.open({ mode: 'update', id: logisticsTask.id, offerId: logisticsTask.offer.id, type: logisticsTask.type as ManuallyCreatableLogisticsType })"
              >
                Ändern
              </n-button>
            </div>
          </template>
          <TableOneDataColumn
            :config="{
              columns: isForMobile ? logisticsTaskGeneralInformationForDriver : logisticsTaskGeneralInformation,
              data: logisticsTask,
            }"
          />
        </TheDataCard>
        <LogisticsAtoBTaskPositionOverview v-if="isSpecialOffer && logisticsTask.offerId" :offer-id="logisticsTask.offerId" />
        <TheDataCard v-if="!isSpecialOffer">
          <template #header>
            <div class="CardHeader">
              <h2>Geräteübersicht</h2>
            </div>
          </template>
          <TableOneDataColumn :config="{ columns: logisticsTaskPositionsOverview, data: logisticsTask.positionsToShip }" />
        </TheDataCard>

        <DriverDocumentCard :logistics-task="logisticsTask" />
      </div>
      <div class="flex w-1/2 flex-col gap-y-2" :class="{ 'w-full': isForMobile }">
        <LogisticsDetailsCard
          v-if="outboundTask && offerHasMachineryOrAccessories"
          :task="outboundTask"
        />

        <DriverLoadingChecklistDataCard v-if="outboundTask" :logistics-task="logisticsTask" />

        <TheDataCard v-if="outboundTask || aToBTask">
          <template #header>
            <div class="CardHeader">
              <h2>Übergabe-Checklist</h2>
              <div class="flex flex-col gap-y-2">
                <n-button v-if="!isDeliveryChecklistEmpty || isAToBDelivered" @click="openDeliveryChecklist">
                  <template #icon>
                    <Icon name="material-symbols:edit-outline-rounded" />
                  </template>Ändern
                </n-button>
                <n-button v-if="!isDeliveryChecklistEmpty || isAToBDelivered" @click="navigateToDeliveryPDF">
                  <template #icon>
                    <Icon name="material-symbols:description-outline-rounded" />
                  </template>PDF
                </n-button>
              </div>
            </div>
          </template>
          <div v-if="aToBTask && isAToBDelivered">
            <TableOneDataColumn :config="{ columns: deliveryChecklist, data: aToBTask }" />
            <FileView v-if="!isSpecialOffer" label="Fotos" wrap-path-in-object :files="aToBTask.deliveryPhotos" />
          </div>
          <div v-else-if="outboundTask">
            <div v-if="logisticsTask.status === 'created'" class="text-center mt-5">
              Die Geräte müssen zunächst beladen werden.
            </div>
            <div v-else>
              <div v-if="isDeliveryChecklistEmpty" class="flex justify-between">
                <span class="opacity-50">Leer</span>
                <n-button @click="openDeliveryChecklist">
                  Die Checkliste ausfüllen
                </n-button>
              </div>
              <div v-else>
                <div v-if="isMachineryHandedOver" class="flex items-center gap-2">
                  <Icon v-if="machineryOption.value" name="material-symbols:check-box-outline-rounded" class="text-green-500" />
                  <Icon v-else name="material-symbols:check-box-outline-blank" />
                  <span>{{ machineryOption.label }}</span>
                </div>

                <div v-for="(option, index) of machineryAccessoryOptions" :key="index" class="flex items-center gap-2">
                  <Icon v-if="option.value" name="material-symbols:check-box-outline-rounded" class="text-green-500" />
                  <Icon v-else name="material-symbols:check-box-outline-blank" />
                  <span>{{ option.label }}</span>
                </div>

                <div v-for="(position, index) in specialPositionsOfOffer" :key="index" class="flex items-center gap-2">
                  <Icon v-if="position.isDelivered" name="material-symbols:check-box-outline-rounded" class="text-green-500" />
                  <Icon v-else name="material-symbols:check-box-outline-blank" />
                  <span>{{ position.title }}</span>
                </div>

                <TableOneDataColumn :config="{ columns: deliveryChecklist, data: outboundTask }" />
                <FileView label="Fotos" wrap-path-in-object :files="outboundTask.deliveryPhotos" />
              </div>
            </div>
          </div>
        </TheDataCard>

        <DriverCollectionChecklistDataCard v-if="inboundTask" :logistics-task="logisticsTask" />

        <TheDataCard v-if="aToBTask">
          <template #header>
            <div class="CardHeader">
              <h2>Abgeschlossen</h2>
              <n-button
                :disabled="!isAToBDelivered"
                @click="openEditTaskAToBStatusPopup.open({ id: logisticsTask.id })"
              >
                Abgeschlossen
              </n-button>
            </div>
            <div v-if="aToBTask.status === 'completed'">
              <TableOneDataColumn :config="{ columns: logisticsCompletedInformation, data: aToBTask }" />
            </div>
            <div v-else>
              Die Spezialfahrt ist noch nicht abgeschlossen
            </div>
          </template>
        </TheDataCard>

        <LogisticsDetailsCard
          v-if="inboundTask && offerHasMachineryOrAccessories"
          :task="logisticsTask"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.CardHeader {
  @apply  w-full flex justify-between mb-5
}
.CardHeader h2 {
  @apply text-lg font-semibold
}
</style>
